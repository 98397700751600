const ListItem = ({ itemHeader, itemId, itemLink, itemDetails }) => {
  return (
    <div className="flex flex-row gap-x-4 pt-3 first:pt-0">
      <div className="flex flex-col flex-grow">
        <div className="flex flex-row justify-between">
          <h3 className="text-sm font-medium">{itemHeader}</h3>
          <h3 className="text-xs text-gray-300">
            {itemLink ? (
              <a href={itemLink} target="_blank">
                {itemId}
              </a>
            ) : (
              <>{itemId}</>
            )}
          </h3>
        </div>
        <div className="flex flex-row gap-x-1">
          {itemDetails
            .flat()
            .filter((i) => i)
            .map((item) => (
              <span className="text-sm after:content-['•'] after:ml-1 last:after:content-[''] last:after:ml-0">
                {item}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
