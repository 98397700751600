import Icon from "./Icon";

const ButtonSecondary = ({ text, icon, className, ...props }) => {
  return (
    <button
      className={`flex items-center gap-x-2 px-3 py-2 border border-black bg-white rounded text-sm hover:drop-shadow-md ${className}`}
      {...props}>
      {icon ? <Icon icon={icon} /> : null}
      {text}
    </button>
  );
};

export default ButtonSecondary;
