const Add = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path fill="none" d="M0 0h16v16H0z" />
      <path d="M7.034.5h1.934v6.533H15.5v1.934H8.967V15.5H7.033V8.966H.5V7.032h6.534Z" />
    </svg>
  );
};

export default Add;
