import Icon from "./Icon";

const ButtonPrimaryStyle =
  "flex items-center gap-x-2 px-3 py-2 rounded text-sm text-white bg-black border border-black fill-white hover:drop-shadow-md transition";

const ButtonPrimary = ({ text, icon, className, ...props }) => {
  return (
    <button className={`${ButtonPrimaryStyle} ${className}`} {...props}>
      {icon ? <Icon icon={icon} /> : null}
      {text}
    </button>
  );
};

export default ButtonPrimary;
export { ButtonPrimaryStyle };
