const LoadingSpinner = ({ item }) => {
  return (
    <div className="inline-flex items-center justify-center gap-6">
      <p>Loading {item} ...</p>
      {/* Add spinner */}
    </div>
  );
};

export default LoadingSpinner;
