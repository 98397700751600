import _ from "lodash";

import { concatStrings } from "../../lib/utility";

const renderUser = (user) => {
  if (user.firstName || user.lastName) {
    return (
      <div className="flex flex-col">
        <div>{concatStrings([user.firstName, user.lastName])}</div>
        <p className="text-gray-300">{user.email ?? user.id}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div>{user.id}</div>
      <p className="text-gray-300">{user.email}</p>
    </div>
  );
};

export const User = ({ value }) => {
  const user = JSON.parse(value);
  return renderUser(user);
};
