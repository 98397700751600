import { NavLink } from "react-router-dom";

import Icon from "./Icon";

const NavItem = ({ to, label, icon, className, ...props }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center px-3 py-2.5 gap-x-3 rounded hover:bg-gray-100 ${
          isActive && "bg-gray-100"
        }`
      }>
      {({ isActive }) => (
        <>
          <Icon
            icon={icon}
            height="h-4"
            width="w-4"
            className={isActive ? "fill-black" : "fill-gray-400"}
          />
          <h3
            className={`${
              isActive ? "text-black" : "text-gray-400"
            } font-medium`}>
            {label}
          </h3>
        </>
      )}
    </NavLink>
  );
};

export default NavItem;
