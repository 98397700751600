import _, { get } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import startCase from "lodash/startCase";

import { getAccount, getIgnitionSession, getUser } from "../lib/automatic";
import LoadingSpinner from "../components/LoadingSpinner";
import PolicyCard from "../components/PolicyCard";

import {
  DetailsPage,
  DetailsPageCard,
  DetailsPageColumn,
} from "../components/DetailsPage";

const IgnitionSessionDetails = () => {
  let params = useParams();
  const [ignitionSession, setIgnitionSession] = useState();
  const [user, setUser] = useState();
  const [account, setAccount] = useState();
  const [policies, setPolicies] = useState();

  // const { user, route } = useAuthenticator((context) => [context.user, context.route]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (ignitionToken) => {
    // Fetch the ignition session from the server and set it to state
    const fetchedIgnitionSession = await getIgnitionSession(
      ignitionToken
    ).catch((e) => console.log(e));

    setIgnitionSession(fetchedIgnitionSession);

    // If session has a user, fetch the user from the server and set to state
    if (fetchedIgnitionSession.user) {
      const fetchedUser = await getUser(
        get(fetchedIgnitionSession, "user.id")
      ).catch((e) => console.log(e));

      setUser(fetchedUser);
    }

    // If session has an account, fetch the expanded account from the server and set it and it's policies to state
    if (fetchedIgnitionSession.account) {
      const fetchedAccount = await getAccount(fetchedIgnitionSession.account, {
        expand: true,
      }).catch((e) => console.log(e));

      setAccount(fetchedAccount);
      setPolicies(fetchedAccount.policies);
    }

    setIsLoading(false);
    return;
  };

  useEffect(() => {
    fetchData(params.ignitionToken);
  }, []);

  const renderDetails = ignitionSession && (
    <DetailsPage
      title={ignitionSession.id}
      badge={ignitionSession.status}
      actions={[
        {
          type: "copy",
          label: "Copy ignition link",
          value: `https://ignition${
            process.env.REACT_APP_STAGE === "prod"
              ? ""
              : `.${process.env.REACT_APP_STAGE}`
          }.axle.insure/?token=${ignitionSession.id}`,
        },
        {
          type: "copy",
          label: "Copy ignition token",
          value: ignitionSession.id,
        },
      ]}>
      {/* Primary column */}
      <DetailsPageColumn primary>
        {/* Session Overview */}
        <DetailsPageCard title="Overview">
          <div className="flex flex-row md:divide-x gap-x-6">
            <div className="flex flex-col">
              <h3 className="uppercase text-[0.625rem] font-medium">
                Last Update
              </h3>
              <p className="text-sm">
                {new Date(_(ignitionSession).get("createdAt")).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    timeZoneName: "short",
                  }
                ) ?? "—"}
              </p>
            </div>
            <div className="flex flex-col pl-6">
              <h3 className="uppercase text-[0.625rem] font-medium">Result</h3>
              <p className="text-sm">
                {_(ignitionSession).get("lastEvent.data.result") ?? "—"}
              </p>
            </div>
          </div>
        </DetailsPageCard>

        {/* Policies */}
        {policies && (
          <DetailsPageCard title="Policies">
            {/* Policy cards */}
            <div className="flex flex-row gap-x-4 overflow-scroll -mr-8">
              {policies.map((policy) => (
                <PolicyCard policy={policy} key={policy.id} />
              ))}
            </div>
          </DetailsPageCard>
        )}

        {/* Session Details*/}
        <DetailsPageCard title="Session">
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-row">
              <div className="flex flex-col w-44 gap-y-2 text-gray-600">
                <p className="text-sm">Type</p>
              </div>
              <div className="flex flex-col grow">
                <p className="text-sm">
                  {_(ignitionSession).get("type") ?? "—"}
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col min-w-[11rem] gap-y-2 text-gray-600">
                <p className="text-sm">Link</p>
              </div>
              <div className="flex flex-col grow">
                <p className="text-sm">
                  {_(ignitionSession)
                    .chain()
                    .get("id")
                    .thru(
                      (token) =>
                        `https://ignition${
                          process.env.REACT_APP_STAGE === "prod"
                            ? ""
                            : `.${process.env.REACT_APP_STAGE}`
                        }.axle.insure/?token=${token}`
                    )
                    .value() ?? "—"}
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col w-44 gap-y-2 text-gray-600">
                <p className="text-sm">Redirect URL</p>
              </div>
              <div className="flex flex-col grow">
                <p className="text-sm">
                  {_(ignitionSession).get("redirectUri") ?? "—"}
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col w-44 gap-y-2 text-gray-600">
                <p className="text-sm">Webhook URL</p>
              </div>
              <div className="flex flex-col grow">
                <p className="text-sm">
                  {_(ignitionSession).get("webhookUri") ?? "—"}
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col w-44 gap-y-2 text-gray-600">
                <p className="text-sm">Created</p>
              </div>
              <div className="flex flex-col grow">
                <p className="text-sm">
                  {_(ignitionSession).get("createdAt")
                    ? new Date(
                        _(ignitionSession).get("createdAt")
                      ).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZoneName: "short",
                      })
                    : "—"}
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col w-44 gap-y-2 text-gray-600">
                <p className="text-sm">Last modified</p>
              </div>
              <div className="flex flex-col grow">
                <p className="text-sm">
                  {_(ignitionSession).get("modifiedAt")
                    ? new Date(
                        _(ignitionSession).get("modifiedAt")
                      ).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZoneName: "short",
                      })
                    : "—"}
                </p>
              </div>
            </div>
          </div>
        </DetailsPageCard>
      </DetailsPageColumn>

      {/* Secondary column */}
      <DetailsPageColumn secondary>
        {/* User */}
        {user && (
          <DetailsPageCard title="User">
            <div className="flex flex-col divide-y gap-y-2">
              <div className="flex flex-col">
                <h3 className="uppercase text-[0.625rem] font-medium">Name</h3>
                <p className="text-sm">
                  {/* This should be replaced with ignitionSession info*/}
                  {startCase(user.firstName)} {startCase(user.lastName)}
                </p>
              </div>
              <div className="flex flex-col pt-2">
                <h3 className="uppercase text-[0.625rem] font-medium">Phone</h3>
                <p className="text-sm">{user.phone}</p>
              </div>
              <div className="flex flex-col pt-2">
                <h3 className="uppercase text-[0.625rem] font-medium">Email</h3>
                <p className="text-sm">{user.email ?? "—"}</p>
              </div>
            </div>
            {/* This should be filled in with information from the user object */}
          </DetailsPageCard>
        )}
      </DetailsPageColumn>
    </DetailsPage>
  );

  return <>{isLoading ? <LoadingSpinner item="details" /> : renderDetails}</>;
};

export default IgnitionSessionDetails;
