const Warning = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M7.9906 12.2397C8.29142 12.2397 8.53897 12.1457 8.73326 11.9577C8.92754 11.7697 9.02468 11.5253 9.02468 11.2244C9.02468 10.9236 8.92754 10.6729 8.73326 10.4724C8.53897 10.2718 8.29142 10.1716 7.9906 10.1716C7.68978 10.1716 7.44222 10.2718 7.24794 10.4724C7.05366 10.6729 6.95652 10.9236 6.95652 11.2244C6.95652 11.5253 7.05366 11.7697 7.24794 11.9577C7.44222 12.1457 7.68978 12.2397 7.9906 12.2397ZM7.16334 8.91187H8.93067V3.83549H7.16334V8.91187ZM7.9906 16C6.88758 16 5.85037 15.7932 4.87897 15.3796C3.90756 14.9659 3.05836 14.3956 2.33137 13.6686C1.60439 12.9416 1.03408 12.0956 0.620447 11.1304C0.206816 10.1653 0 9.12495 0 8.0094C0 6.89385 0.206816 5.85037 0.620447 4.87897C1.03408 3.90756 1.60439 3.0615 2.33137 2.34078C3.05836 1.62006 3.90443 1.04975 4.86957 0.629847C5.8347 0.209949 6.87505 0 7.9906 0C9.10615 0 10.1496 0.209949 11.121 0.629847C12.0924 1.04975 12.9385 1.62006 13.6592 2.34078C14.3799 3.0615 14.9503 3.90756 15.3702 4.87897C15.7901 5.85037 16 6.89385 16 8.0094C16 9.12495 15.7901 10.1653 15.3702 11.1304C14.9503 12.0956 14.3799 12.9385 13.6592 13.6592C12.9385 14.3799 12.0924 14.9503 11.121 15.3702C10.1496 15.7901 9.10615 16 7.9906 16ZM8.0094 14.2327C9.7266 14.2327 11.19 13.6279 12.3995 12.4183C13.6091 11.2088 14.2139 9.73286 14.2139 7.9906C14.2139 6.2734 13.6091 4.81003 12.3995 3.60047C11.19 2.39091 9.72033 1.78613 7.9906 1.78613C6.26087 1.78613 4.79123 2.39091 3.58167 3.60047C2.37211 4.81003 1.76733 6.27967 1.76733 8.0094C1.76733 9.73913 2.37211 11.2088 3.58167 12.4183C4.79123 13.6279 6.26714 14.2327 8.0094 14.2327Z"
      />
    </svg>
  );
};

export default Warning;
