const Open = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M1 15V8.94595H2.51351V12.427L12.427 2.51351H8.94595V1H15V7.05405H13.4865V3.57297L3.57297 13.4865H7.05405V15H1Z"
      />
    </svg>
  );
};

export default Open;
