import _, { startCase } from "lodash";
import { DateTime } from "luxon";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import React, { useState, useEffect, useMemo } from "react";

import { getIgnitionSessions } from "../lib/automatic";
import { Metadata } from "./table/Metadata";
import { SelectColumnFilter } from "./table/Filter";
import { User } from "./table/User";
import Badge from "./Badge";
import LoadingSpinner from "./LoadingSpinner";
import Table from "./table/Table";

import seedData from "../seed/ignitionSessions.json";

const IgnitionSessionsTable = () => {
  const now = DateTime.local().endOf("day"); // Set to end of day local time to be inclusive in date range

  const { promiseInProgress } = usePromiseTracker();
  const [ignitionSessions, setIgnitionSessions] = useState([]);
  const [startDate, setStartDate] = useState(
    now.minus({ months: 1 }).startOf("day").toJSDate() // Set to start of day local time to be inclusive in date range
  );
  const [endDate, setEndDate] = useState(now.toJSDate());

  const columns = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: ({ value }) => {
          return value ? <Badge status={value} /> : "—";
        },
        width: 75,
      },

      {
        id: "user",
        Header: "User",
        accessor: (row) => {
          const user = _(row).get("user");
          return JSON.stringify(user);
        },
        Cell: ({ value }) => {
          return value ? <User value={value} /> : "—";
        },
        width: 200,
        isObject: true,
      },

      {
        Header: "Carrier",
        accessor: "carrier",
        Cell: ({ value }) => {
          return startCase(value) || "—";
        },
        width: 100,
      },

      {
        id: "metadata",
        Header: "Metadata",
        accessor: (row) => JSON.stringify(row.metadata),
        Cell: ({ value }) => {
          return value ? <Metadata value={value} /> : "—";
        },
        width: 150,
        isObject: true,
      },

      {
        id: "lastUpdated",
        Header: "Last Updated",
        accessor: (row) => row.modifiedAt || row.createdAt,
        disableFilters: true,
        Cell: ({ value }) => {
          return value
            ? DateTime.fromISO(value).toLocaleString({
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })
            : "—";
        },
        width: 75,
      },
      {
        accessor: "id",
        show: false,
        className: "hidden",
      },
      {
        accessor: "account",
        show: false,
        className: "hidden",
      },
      {
        accessor: "createdAt",
        show: false,
        className: "hidden",
      },
      {
        accessor: "modifiedAt",
        show: false,
        className: "hidden",
      },
    ],
    []
  );

  useEffect(() => {
    // Use seed data if set to true
    if (process.env.REACT_APP_MOCK === "true") {
      setIgnitionSessions(seedData);
      return;
    }

    // Retrieve ignition sessions and set to state
    trackPromise(
      getIgnitionSessions({
        start: startDate && startDate.toISOString(),
        end: endDate && endDate.toISOString(),
      }).then((data) => {
        setIgnitionSessions(data);
      })
    );
  }, [endDate]);

  return (
    <>
      {promiseInProgress === true ? (
        <LoadingSpinner item="Ignition sessions" />
      ) : (
        <>
          <Table
            entity={"ignition-sessions"}
            data={ignitionSessions}
            columns={columns}
            rowBehavior={{
              type: "link",
              config: { path: "/ignition", resourceKey: "id" },
            }}
            globalFilterPlaceholder={
              "Enter any user, status, carrier, or metadata..."
            }
            csvFields={[
              "id",
              "status",
              "carrier",
              "account",
              "user",
              "metadata",
              "createdAt",
              "modifiedAt",
            ]}
            dateFilter={{ startDate, endDate, setStartDate, setEndDate }}
            // searchItems={searchItems}
          />
        </>
      )}
    </>
  );
};

export default IgnitionSessionsTable;
