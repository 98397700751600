import { useEffect, useState } from "react";
import Badge, { lookupStatusConfig } from "./Badge";
import Icon from "./Icon";
import React from "react";
import { validatePolicy } from "../lib/automatic";
import { get } from "lodash";
import LoadingSpinner from "./LoadingSpinner";
import { rule } from "postcss";

const MAP_RESULT_TO_HEADER = {
  "policy-active": "Policy Active",
  "rental-covered-for-collision":
    "Coverage for Collision Damage to Rental Vehicles",
  "collision-exists": "Collision Coverage Exists",
  "collision-coverage-extends-to-rental":
    "Collision Coverage Extends to Rental Vehicles",
  "property-damage-covers-rental-collision":
    "Property Damage Coverage Covers Collision Damage to Rental Vehicles",
  "endorsement-covers-rental-collision":
    "Endorsement Covers Collision Damage to Rental Vehicles",
};

const callValidatePolicy = async ({ client, policyId }) => {
  // Only get rules for this policy if validation rules are enabled on the client
  try {
    if (get(client, "config.validationConfig.enabled")) {
      return await validatePolicy(policyId);
    }
  } catch (e) {
    console.log(e);
  }
};

const PolicyValidation = ({ title, iconStatus, client, policyId }) => {
  const [loading, setLoading] = useState(true);
  const [rules, setRules] = useState({});
  const [badgeStatus, setBadgeStatus] = useState();
  const statusConfig = lookupStatusConfig(iconStatus);
  useEffect(() => {
    if (loading) {
      callValidatePolicy({ client, policyId })
        .then((data) => {
          setRules(data.rules);
          setBadgeStatus(data.status);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  // Get the keys of the rules object as an array
  const ruleKeys = Object.keys(rules);

  // Generate the react elements for each rule
  const ruleObjects = ruleKeys.map((key) => {
    const rule = get(rules, key);

    const reactElement = (
      <>
        <div className="py-6 last:pb-0">
          {/* <Badge status={check.status} /> */}
          <HeaderWithIcon
            badgeStatus={rule.status}
            name={get(MAP_RESULT_TO_HEADER, key, key)}
            message={get(rule, "message.displayText")}
            breakdown={get(rule, "breakdown")}
          />
        </div>
      </>
    );

    return reactElement;
  });

  return (
    <>
      <div
        className={`flex flex-col gap-y-6 border border-gray-300 rounded p-8 pb-10 divide-y`}
      >
        <div className="flex flex-row justify-between">
          <h2 className=" text-base flex gap-1 items-center">
            {title}
            {iconStatus && (
              <>
                <Icon
                  icon={statusConfig.icon}
                  width={statusConfig.width}
                  height={statusConfig.height}
                />
              </>
            )}
          </h2>
          {badgeStatus && <Badge status={badgeStatus} />}
        </div>
        {loading ? (
          <LoadingSpinner item={"rules"}></LoadingSpinner>
        ) : (
          <>
            <div className="flex flex-col">{ruleObjects}</div>
          </>
        )}
      </div>
    </>
  );
};

const HeaderWithIcon = ({ badgeStatus, name, message, breakdown }) => {
  return (
    <>
      <div className="flex flex-row justify-between gap-8">
        <div className="flex flex-col gap-1">
          <h2 className="text-base flex gap-2 items-center">{name} </h2>
          <p className="text-sm">{message}</p>

          {/* Breakdown (We decided to remove for now */}
          {/* {breakdown && (
            <div className="flex flex-col gap-4 ml-4 mt-4">
              <h3 className="text-sm flex gap-2 items-center">Breakdown: </h3>
              {Object.keys(breakdown).map((key) => {
                const subValidation = breakdown[key];

                return (
                  <div className="flex flex-row gap-2 justify-between">
                    <div className="">
                      <p className="text-xs">
                        {get(MAP_RESULT_TO_HEADER, key, key)}
                      </p>
                    </div>
                    <div className="h-2/4">
                      <Badge status={subValidation.status} />
                    </div>
                  </div>
                );
              })}
            </div>
          )} */}
        </div>
        <div className="h-2/4">
          {badgeStatus && (
            <>
              <Badge status={badgeStatus} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PolicyValidation;
