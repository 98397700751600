import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { startCase, upperFirst } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";

import { getCarriers } from "../lib/automatic";
import { SelectColumnFilter } from "./table/Filter";
import Badge from "./Badge";
import Carrier from "./table/Carrier";
import LoadingSpinner from "./LoadingSpinner";
import Table from "./table/Table";
import { get } from "lodash";

import seedData from "../seed/carriers.json";

const calculateMarketShare = (carriers, type, policyType) => {
  // Filter carriers by type
  const filteredCarriers = carriers.filter((carrier) =>
    type ? carrier.type === type : carrier
  );

  // Sum market share
  const totalMarketShare = filteredCarriers.reduce((sum, carrier) => {
    return get(carrier, `share.${policyType}`)
      ? sum + get(carrier, `share.${policyType}`)
      : sum;
  }, 0);

  // Format and return
  const formattedMarketShare = totalMarketShare.toLocaleString(undefined, {
    style: "percent",
    maximumFractionDigits: 0,
  });
  return formattedMarketShare;
};

const CarriersTable = () => {
  const { promiseInProgress } = usePromiseTracker();
  const [carriers, setCarriers] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Carrier",
        accessor: (row) => {
          const { id, name, domain, hasImage } = row;
          return JSON.stringify({ id, name, domain, hasImage });
        },
        Cell: ({ value }) => {
          const carrier = JSON.parse(value);
          return <Carrier carrier={carrier} />;
        },
        width: 400,
      },

      {
        Header: "Supported policies",
        Filter: SelectColumnFilter,
        accessor: "policyTypes", // accessor is the "key" in the data
        Cell: ({ value }) => {
          const startCaseVals = value.map((val) => {
            return startCase(val);
          });
          return _(startCaseVals).join(", ");
        },
        width: 100,
        // className: "flex-none"
      },

      {
        Header: "Integration type",
        Filter: SelectColumnFilter,
        accessor: "type", // accessor is the "key" in the data
        Cell: ({ value }) => {
          return value ? upperFirst(value) : "—";
        },
        width: 100,
        // className: "flex-none"
      },

      {
        Header: "Status",
        Filter: SelectColumnFilter,
        accessor: "status",
        Cell: ({ value }) => {
          return <Badge status={value} />;
        },
        width: 100,
      },
    ],
    []
  );

  const quickFilters = [
    {
      id: 1,
      label: "All integrations",
      subLabel: `${calculateMarketShare(
        carriers,
        null,
        "auto"
      )} auto + ${calculateMarketShare(carriers, null, "home")} home coverage`,
      column: "type",
      value: null,
    },
    {
      id: 2,
      label: "Advanced integrations",
      subLabel: `${calculateMarketShare(
        carriers,
        "advanced",
        "auto"
      )} auto + ${calculateMarketShare(
        carriers,
        "advanced",
        "home"
      )} home coverage`,
      column: "type",
      value: "advanced",
    },
    {
      id: 3,
      label: "Basic integrations",
      subLabel: `${calculateMarketShare(
        carriers,
        "basic",
        "auto"
      )} auto + ${calculateMarketShare(
        carriers,
        "basic",
        "home"
      )} home coverage`,
      column: "type",
      value: "basic",
    },
  ];

  useEffect(() => {
    // Use seed data if set to true
    if (process.env.REACT_APP_MOCK === "true") {
      setCarriers(seedData);
      return;
    }

    // Retrieve ignition sessions and set to state
    trackPromise(
      getCarriers().then((data) => {
        setCarriers(data);
      })
    );
  }, []);

  return (
    <>
      {promiseInProgress === true ? (
        <LoadingSpinner item="Carriers" />
      ) : (
        <>
          <Table
            data={carriers}
            columns={columns}
            // linkPath={linkPath}
            resourceKey="id"
            // searchItems={searchItems}
            globalFilterPlaceholder={
              "Enter any carrier name, integration type, or status..."
            }
            quickFilters={quickFilters}
          />
        </>
      )}
    </>
  );
};

export default CarriersTable;
