const Exit = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M6.88785 11.9427L5.78505 10.8615L7.87851 8.77364H1V7.28229H7.87851L5.78505 5.19441L6.88785 4.11318L10.8131 8.02796L6.88785 11.9427ZM2 15C1.44772 15 1 14.5523 1 14V9.89214H2.47664V13.5273H13.5047V2.49134H2.47664V6.16378H1V2C1 1.44771 1.44772 1 2 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H2Z"
      />
    </svg>
  );
};

export default Exit;
