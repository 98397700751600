import { React, useMemo, useState, Fragment } from "react";
import { useAsyncDebounce } from "react-table";
import { RadioGroup } from "@headlessui/react";

import Icon from "../Icon";
import Search from "../../icons/Search";

// Component for Global Filter
export function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  globalFilterPlaceholder,
}) {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex flex-grow items-center bg-gray-50 rounded pl-3">
      <Icon icon={Search} />
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={
          globalFilterPlaceholder ?? "Enter any user, status, or metadata..."
        }
        // className="p-3 border border-gray-200 rounded text-sm"
        className="bg-transparent text-sm grow p-3"
      />
    </div>
  );
}

// Component for quick filter
export function QuickFilter({ setFilter, filters }) {
  const [quickFilter, setQuickFilter] = useState(filters[0].label);

  const setTableFilter = useAsyncDebounce((radio) => {
    setFilter(radio.column, radio.value || undefined);
  }, 200);

  return (
    <RadioGroup
      value={quickFilter}
      onChange={setQuickFilter}
      className="flex flex-row gap-x-4"
    >
      {filters.map((filter) => (
        <RadioGroup.Option
          key={filter.label}
          value={filter.label}
          onClick={() => setTableFilter(filter)}
          className={({ active, checked }) =>
            `${checked ? "border-black" : "border-gray-200 text-gray-600"}
            flex flex-col gap-y-1 p-4 border border-black rounded cursor-pointer w-64 flex-shrink`
          }
        >
          <p className=" text-base font-semibold">{filter.label}</p>
          <p className=" text-sm">{filter.subLabel}</p>
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
}

// Component for Default Column Filter
export function DefaultFilterForColumn({
  column: {
    filterValue,
    preFilteredRows: { length },
    setFilter,
  },
}) {
  const [value, setValue] = useState(filterValue);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 300);
  return (
    <input
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        // Set undefined to remove the filter entirely
        setFilter(e.target.value);
      }}
      placeholder={`Filter ${length} records...`}
      className="mt-2"
    />
  );
}

// Component for Custom Select Filter
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Use preFilteredRows to calculate the options
  let options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (Array.isArray(row.values[id])) {
        for (const val of row.values[id].values()) {
          options.add(val);
        }
      } else {
        options.add(row.values[id]);
      }
    });
    return [...options.values()].filter((val) => val);
  }, [id, preFilteredRows]);
  // UI for Multi-Select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className="mt-2"
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
