import { Link } from "react-router-dom";
import React from "react";

import Add from "../icons/Add";
import ButtonPrimary from "../components/ButtonPrimary";
import IgnitionSessionsTable from "../components/IgnitionSessionsTable";

const IgnitionSessions = () => {
  return (
    <div className="flex flex-col flex-1 h-full px-12 gap-y-8">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Ignition Sessions</h1>
        <div className="flex gap-x-2">
          {/* <ButtonSecondary text="Filter" icon="Filter" /> */}
          <Link to="/ignition/new">
            <ButtonPrimary text="New Ignition Session" icon={Add} />
          </Link>
        </div>
      </div>
      <IgnitionSessionsTable />
    </div>
  );
};

export default IgnitionSessions;
