const Checkmark = (props) => {
  return (
      <svg
        width={"inherit"}
        height={"inherit"}
        viewBox="0 0 16 16"
        {...props}
      >
        <rect
          id="Rectangle_50"
          data-name="Rectangle 50"
          width="16"
          height="16"
          transform="translate(0)"
          fill="none"
        />
        <path
          id="Subtraction_4"
          data-name="Subtraction 4"
          d="M-1063.685-3073.512h0l-5.5-5.5,1.439-1.439,4.057,4.058,9.064-9.065,1.439,1.439-10.5,10.5Z"
          transform="translate(1069.182 3087.455)"
        />
      </svg>
  );
};

export default Checkmark;
