import toast from "react-hot-toast";
import Badge, { lookupStatusConfig } from "./Badge";
import Icon from "./Icon";
import { useEffect, useState } from "react";
import React from "react";

const DetailsPage = ({ title, badge, actions, children }) => {
  return (
    <>
      <div className="flex flex-col px-12 gap-y-9 h-fit">
        {/* Page header */}
        <div className="flex flex-row items-center gap-x-4">
          {/* Page title */}
          <h1 className="text-2xl font-bold inline-flex gap-3">{title}</h1>

          {/* Badge - typically a status */}
          {badge && <Badge status={badge} />}

          {/* Actions - currently supports copy buttons */}
          <div className="flex flex-row ml-auto">
            {
              // Only supports "copy" actions
              actions.map((action) => (
                <h4
                  className="text-right text-gray-500 bg-gray-50 hover:text-black hover:bg-gray-100 px-2 py-1 transition rounded-sm cursor-pointer mr-3"
                  onClick={() => {
                    navigator.clipboard.writeText(action.value);
                    toast.success("Copied to clipboard");
                  }}
                >
                  {action.label}
                </h4>
              ))
            }
          </div>
        </div>
        {/* Page details */}
        <div className="flex flex-row gap-6 flex-wrap">{children}</div>
      </div>
    </>
  );
};

const DetailsPageColumn = ({ primary, children }) => {
  return (
    <div
      className={`flex flex-col gap-y-6 flex-grow  ${
        primary ? "basis-2/3" : "basis-3/10"
      }`}
    >
      {children}
    </div>
  );
};

const DetailsPageCard = ({ title, children, iconStatus, badgeStatus }) => {
  const statusConfig = lookupStatusConfig(iconStatus);

  return (
    <>
      <div
        className={`flex flex-col gap-y-6 border border-gray-300 rounded p-8 pb-10`}
      >
        <div className="flex flex-row justify-between">
          <h2 className=" text-base flex gap-1 items-center">
            {title}
            {iconStatus && (
              <>
                <Icon
                  icon={statusConfig.icon}
                  width={statusConfig.width}
                  height={statusConfig.height}
                />
              </>
            )}
          </h2>
          {badgeStatus && <Badge status={badgeStatus} />}
        </div>
        {children}
      </div>
    </>
  );
};

export { DetailsPage, DetailsPageCard, DetailsPageColumn };
