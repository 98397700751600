import upperFirst from "lodash/upperFirst";

import Checkmark from "../icons/Checkmark";
import Clock from "../icons/Clock";
import Close from "../icons/Close";
import Disconnected from "../icons/Disconnected";
import Empty from "../icons/Empty";
import Exit from "../icons/Exit";
import Icon from "./Icon";
import Open from "../icons/Open";
import Warning from "../icons/Warning";
import Bolt from "../icons/Bolt";
import Shimmer from "../icons/Shimmer";

export const lookupStatusConfig = (status) => {
  switch (status) {
    case "pending":
      return {
        icon: Clock,
        color: "bg-gray-200 text-gray-800 fill-gray-800",
      };
    case "opened":
      return {
        icon: Open,
        color: "bg-blue-200 text-blue-800 fill-blue-800",
      };
    case "active":
    case "success":
    case "completed":
    case "pass":
      return {
        icon: Checkmark,
        color: "bg-green-200 text-green-800 fill-green-800",
      };
    case "error":
    case "carrier-error":
    case "manual":
    case "warning":
    case "caution":
      return {
        icon: Warning,
        color: "bg-amber-200 text-amber-800 fill-amber-800",
      };
    case "no-policies":
      return {
        icon: Empty,
        color: "bg-amber-200 text-amber-800 fill-amber-800",
      };
    case "exited":
      return {
        icon: Exit,
        color: "bg-amber-200 text-amber-800 fill-amber-800",
      };
    case "failed":
      return {
        icon: Close,
        color: "bg-amber-200 text-amber-800 fill-amber-800",
      };
    case "fail":
      return {
        icon: Close,
        color: "bg-red-200 text-red-800 fill-red-800",
      };
    case "disconnected":
      return {
        icon: Disconnected,
        color: "bg-amber-200 text-amber-800 fill-amber-800",
      };
    case "matched":
      return {
        icon: Bolt,
        width: "w-4",
        height: "h-4",
      };
    case "shimmer":
      return {
        icon: Shimmer,
        width: "w-4",
        height: "h-4",
      };
    case "unknown":
    default:
      return {
        icon: Clock,
        color: "bg-gray-200 text-gray-800 fill-gray-800",
      };
  }
};

const Badge = ({ status }) => {
  const statusConfig = lookupStatusConfig(status);
  const badgeText = status ? upperFirst(status.replace("-", " ")) : "Unknown";
  return (
    <div
      className={`flex gap-x-1.5 items-center rounded px-1.5 py-1 max-w-fit ${statusConfig.color}`}
    >
      <Icon icon={statusConfig.icon} />
      {status && <p className="font-medium text-xs">{badgeText}</p>}
    </div>
  );
};

export default Badge;
