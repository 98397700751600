import React from "react";

import CarriersTable from "../components/CarriersTable";

const Carriers = () => {
  return (
    <div className="flex flex-col flex-1 h-full px-12 gap-y-8">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Carriers</h1>
      </div>
      <CarriersTable />
    </div>
  );
};

export default Carriers;
