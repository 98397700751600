import React from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "../components/ButtonPrimary";
import PoliciesTable from "../components/PoliciesTable";

const Policies = () => {
  return (
    <div className="flex flex-col flex-1 h-full px-12 gap-y-8">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Policies</h1>
        {/* <div className="flex gap-x-2">
          <Link to="/ignition/new">
            <ButtonPrimary text="New Ignition Session" icon="Add" />
          </Link>
        </div> */}
      </div>
      <PoliciesTable />
    </div>
  );
};

export default Policies;
