const Shimmer = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <g clip-path="url(#clip0_3080_340)">
        <path
          d="M4.79534 12.1048C4.79534 11.4415 4.66748 10.8181 4.41171 10.2347C4.16397 9.65124 3.82428 9.14372 3.3927 8.71214C2.96112 8.28056 2.45362 7.94088 1.87018 7.69313C1.28675 7.43737 0.663357 7.3095 0 7.3095C0.663357 7.3095 1.28675 7.18561 1.87018 6.93786C2.45362 6.6821 2.96112 6.33844 3.3927 5.90686C3.82428 5.47528 4.16397 4.96778 4.41171 4.38434C4.66748 3.80091 4.79534 3.17752 4.79534 2.51416C4.79534 3.17752 4.91923 3.80091 5.16698 4.38434C5.42274 4.96778 5.7664 5.47528 6.19798 5.90686C6.62956 6.33844 7.13708 6.6821 7.7205 6.93786C8.30392 7.18561 8.92732 7.3095 9.59068 7.3095C8.92732 7.3095 8.30392 7.43737 7.7205 7.69313C7.13708 7.94088 6.62956 8.28056 6.19798 8.71214C5.7664 9.14372 5.42274 9.65124 5.16698 10.2347C4.91923 10.8181 4.79534 11.4415 4.79534 12.1048Z"
          fill="black"
        />
        <path
          d="M12.4694 7.39452C12.4694 6.9061 12.3752 6.44712 12.1869 6.01757C12.0045 5.58802 11.7544 5.21435 11.4366 4.89659C11.1189 4.57884 10.7452 4.32874 10.3157 4.14633C9.88611 3.95803 9.42713 3.86388 8.93872 3.86388C9.42713 3.86388 9.88611 3.77267 10.3157 3.59026C10.7452 3.40195 11.1189 3.14893 11.4366 2.83117C11.7544 2.51342 12.0045 2.13976 12.1869 1.7102C12.3752 1.28064 12.4694 0.821657 12.4694 0.333252C12.4694 0.821657 12.5606 1.28064 12.743 1.7102C12.9313 2.13976 13.1843 2.51342 13.5021 2.83117C13.8198 3.14893 14.1935 3.40195 14.623 3.59026C15.0526 3.77267 15.5116 3.86388 16 3.86388C15.5116 3.86388 15.0526 3.95803 14.623 4.14633C14.1935 4.32874 13.8198 4.57884 13.5021 4.89659C13.1843 5.21435 12.9313 5.58802 12.743 6.01757C12.5606 6.44712 12.4694 6.9061 12.4694 7.39452Z"
          fill="black"
        />
        <path
          d="M10.7243 15.6671C10.7243 15.2376 10.6415 14.8339 10.4759 14.4561C10.3155 14.0783 10.0955 13.7497 9.81606 13.4702C9.53659 13.1908 9.20796 12.9708 8.83016 12.8104C8.45236 12.6448 8.04869 12.562 7.61914 12.562C8.04869 12.562 8.45236 12.4817 8.83016 12.3213C9.20796 12.1557 9.53659 11.9332 9.81606 11.6537C10.0955 11.3742 10.3155 11.0456 10.4759 10.6678C10.6415 10.29 10.7243 9.88634 10.7243 9.45679C10.7243 9.88634 10.8045 10.29 10.965 10.6678C11.1306 11.0456 11.3531 11.3742 11.6326 11.6537C11.9121 11.9332 12.2407 12.1557 12.6185 12.3213C12.9963 12.4817 13.3999 12.562 13.8295 12.562C13.3999 12.562 12.9963 12.6448 12.6185 12.8104C12.2407 12.9708 11.9121 13.1908 11.6326 13.4702C11.3531 13.7497 11.1306 14.0783 10.965 14.4561C10.8045 14.8339 10.7243 15.2376 10.7243 15.6671Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3080_340">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Shimmer;
