import React from "react";
import toast from "react-hot-toast";

import Close from "../icons/Close";
import Icon from "./Icon";

const Toast = ({ t, message }) => {
  return (
    <div
      className={`flex items-start justify-between ${
        t.type === "success" ? "bg-green-600" : null
      } ${
        t.type === "error" ? "bg-red-600" : null
      } text-white text-sm w-1/6 px-4 py-3 shadow-md rounded-md border-none ${
        t.visible ? "animate-enter" : "animate-leave"
      }`}>
      <p>{t.message}</p>
      <Icon
        icon={Close}
        className="flex-none translate-y-1 cursor-pointer transition hover:scale-110 fill-white"
        onClick={() => toast.dismiss(t.id)}
      />
    </div>
  );
};

export default Toast;
