import CarrierLogo from "../CarrierLogo";

const Carrier = ({ carrier }) => {
  return (
    <div className="flex flex-row gap-x-4">
      <CarrierLogo carrier={carrier} />
      <div className="flex flex-col">
        <div>{carrier.name}</div>
        <p className="text-gray-300">{carrier.domain}</p>
      </div>
    </div>
  );
};

export default Carrier;
