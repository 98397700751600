import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import Carriers from "./routes/Carriers";
import IgnitionSessionDetails from "./routes/IgnitionSessionDetails";
import IgnitionSessions from "./routes/IgnitionSessions";
import NewIgnitionSession from "./routes/NewIgnitionSession";
import Policies from "./routes/Policies";
import PolicyDetails from "./routes/PolicyDetails";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="ignition">
            <Route index element={<IgnitionSessions />} />
            <Route path="new" element={<NewIgnitionSession />} />
            <Route path=":ignitionToken" element={<IgnitionSessionDetails />} />
          </Route>
          <Route path="policies">
            <Route index element={<Policies />} />
            <Route path=":policyId" element={<PolicyDetails />} />
          </Route>
          <Route path="" element={<Navigate to="ignition" replace />} />
          <Route path="carriers">
            <Route index element={<Carriers />} />
          </Route>
          {/* <Route path="*" element={<p>There's nothing here!</p>} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
