const ChevronLeft = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M11.0001 16L12.5872 14.413L6.1742 8L12.5872 1.58704L11.0001 0L3.00011 8L11.0001 16Z"
      />
    </svg>
  );
};

export default ChevronLeft;
