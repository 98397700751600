import { DateTime } from "luxon";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { upperFirst } from "lodash";
import React, { useState, useEffect, useMemo } from "react";

import { getPolicies } from "../lib/automatic";
import LoadingSpinner from "./LoadingSpinner";
import Table from "./table/Table";

import seedData from "../seed/policies.json";

const PoliciesTable = () => {
  const { promiseInProgress } = usePromiseTracker();
  const [policies, setPolicies] = useState([]);

  const columns = useMemo(
    () => [
      // Need more policy information to use this
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Filter: SelectColumnFilter,
      //   filter: "includes",
      //   Cell: ({ value }) => {
      //     return <Badge status={value} />;
      //   },
      //   width: 100,
      // },

      {
        Header: "First Name",
        accessor: "user.firstName",
        Cell: ({ value }) => {
          return value ? upperFirst(value) : "—";
        },
        width: 100,
      },

      {
        Header: "Last Name",
        accessor: "user.lastName", // accessor is the "key" in the data
        Cell: ({ value }) => {
          return value ? upperFirst(value) : "—";
        },
        width: 100,
      },

      {
        Header: "User ID",
        accessor: "user.id", // accessor is the "key" in the data
        width: 250,
      },

      // Combined user cell - for when we want to display more policy data
      // {
      //   Header: "User",
      //   accessor: "user",
      //   Cell: ({ value }) => {
      //     return value ? (
      //       <div className="flex flex-col">
      //         {" "}
      //         <div>
      //           {[value.firstName, value.lastName].filter((i) => i).join(" ")}
      //         </div>
      //         <p className="text-gray-300">{value.id}</p>
      //       </div>
      //     ) : (
      //       "—"
      //     );
      //   },
      //   width: 200,
      // },

      {
        Header: "Policy ID",
        accessor: "id",
        width: 250,
      },

      // Need more policy information to use this
      // {
      //   Header: "Policy Number",
      //   accessor: "policyNumber",
      //   Cell: ({ value }) => {
      //     return startCase(value) || "—";
      //   },
      // },

      // Need more policy information to use this
      // {
      //   Header: "Carrier",
      //   accessor: "carrier",
      //   Cell: ({ value }) => {
      //     return startCase(value) || "—";
      //   },
      // },

      {
        Header: "Last Updated",
        accessor: (row) => row.modifiedAt || row.createdAt,
        disableFilters: true,
        Cell: ({ value }) => {
          return value
            ? DateTime.fromISO(value).toLocaleString({
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })
            : "—";
        },
        width: 100,
      },
    ],
    []
  );

  useEffect(() => {
    // Use seed data if set to true
    if (process.env.REACT_APP_MOCK === "true") {
      setPolicies(seedData);
      return;
    }

    // Retrieve ignition sessions and set to state
    trackPromise(
      getPolicies().then((data) => {
        setPolicies(data);
      })
    );
  }, []);

  return (
    <>
      {promiseInProgress === true ? (
        <LoadingSpinner item="policies" />
      ) : (
        <>
          <Table
            data={policies}
            columns={columns}
            rowBehavior={{
              type: "link",
              config: { path: "/policies", resourceKey: "id" },
            }}
            globalFilterPlaceholder={"Enter any user or policy id..."}
            // searchItems={searchItems}
          />
        </>
      )}
    </>
  );
};

export default PoliciesTable;
