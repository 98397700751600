const ChevronRight = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M4.58705 16L3 14.413L9.41296 8L3 1.58704L4.58705 0L12.587 8L4.58705 16Z"
      />
    </svg>
  );
};

export default ChevronRight;
