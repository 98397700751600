import { createElement } from "react";

/**
 * It takes in an icon, height, width, fill, and className, and returns an SVG icon with the given
 * height, width, fill, and className
 * @returns A React component that takes in props and returns an SVG icon.
 */
const Icon = ({ icon, height, width, className, ...props }) => {
  return (
    <>
      {createElement(icon, {
        className: `${height ? height : "h-3"} ${
          width ? width : "w-3"
        } ${className}`,
        ...props,
      })}
    </>
  );
};

export default Icon;
