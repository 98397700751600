const Tooltip = ({ item, tooltip }) => {
  return (
    <div className="group relative">
      {item}
      <div className="hidden group-hover:flex absolute right-0 -top-2 transform -translate-y-full mb-4 flex-col gap-y bg-gray-900 border-none text-gray-50 p-4 rounded shadow-sm">
        {tooltip}
      </div>
    </div>
  );
};

export default Tooltip;
