import _ from "lodash";
import { forwardRef, useState } from "react";
import { replace } from "lodash";
import DatePicker from "react-datepicker";

import ButtonSecondary from "./ButtonSecondary";
import Calendar from "../icons/Calendar";

const parseDateRangeInputValue = (value) => {
  const [dateRangeInputValueStart, dateRangeInputValueEnd] = _(value)
    .chain()
    .split("-")
    .map((i) => replace(i, /\s+/g, ""))
    .value();

  if (!dateRangeInputValueEnd) {
    return dateRangeInputValueStart + " - " + "End date";
  }

  return value;
};

const DateRangeInput = forwardRef(({ value, onClick }, ref) => (
  <ButtonSecondary
    text={parseDateRangeInputValue(value)}
    onClick={onClick}
    ref={ref}
    icon={Calendar}
    className={"w-54"}
  />
));

const DateRangePicker = ({ dateFilter }) => {
  const { startDate, endDate, setStartDate, setEndDate } = dateFilter;
  const [pickerStartDate, setPickerStartDate] = useState(startDate);
  const [pickerEndDate, setPickerEndDate] = useState(endDate);

  const onChange = (dates) => {
    const [start, end] = dates;
    setPickerStartDate(start);
    setPickerEndDate(end);
  };

  const onCalendarClose = () => {
    setStartDate(pickerStartDate);
    setEndDate(pickerEndDate);
  };
  return (
    <DatePicker
      selectsRange
      selected={pickerStartDate}
      startDate={pickerStartDate}
      endDate={pickerEndDate}
      onChange={onChange}
      onCalendarClose={onCalendarClose}
      customInput={<DateRangeInput />}
    />
  );
};

export default DateRangePicker;
