import { Combobox, RadioGroup, Listbox } from "@headlessui/react";
import { Fragment } from "react";
import { React, useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import toast from "react-hot-toast";

import Add from "../icons/Add";
import ButtonPrimary from "../components/ButtonPrimary";
import Checkmark from "../icons/Checkmark";
import Close from "../icons/Close";
import Form from "../components/Form";
import Icon from "../components/Icon";

import {
  createIgnitionSession,
  createUser,
  getPolicies,
  getUsers,
} from "../lib/automatic";
const NewIgnitionSession = () => {
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker();

  // Set up ignitionSession types
  const ignitionSessionTypes = ["link", "refresh"];

  // Setup state
  const [error, setError] = useState(null);
  const [user, setUser] = useState();
  const [type, setType] = useState(ignitionSessionTypes[0]);
  const [policies, setPolicies] = useState([]);
  const [policy, setPolicy] = useState();
  const [newUser, setnewUser] = useState({});
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");
  // const { user, route } = useAuthenticator((context) => [context.user, context.route]);

  useEffect(() => {
    getUsers().then((data) => setUsers(data));
  }, []);

  // Get policies when user and refresh are selected

  useEffect(() => {
    const loadPolicies = async () => {
      try {
        await getPolicies(user.id).then((data) => setPolicies(data));
      } catch (error) {
        console.log(error);
        setError(error);
      }
    };

    if (user && type === "refresh") {
      loadPolicies();
    }
  }, [user, type]);

  const filteredUsers =
    query === ""
      ? users
      : users.filter((user) => {
          return (user.firstName + " " + user.lastName)
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  // Setup New Ignition Session form fields
  const newUserFields = [
    {
      id: "firstName",
      label: "First Name",
      type: "text",
      placeholder: "User first name...",
    },
    {
      id: "lastName",
      label: "Last Name",
      type: "text",
      placeholder: "User last name...",
    },
    {
      id: "phone",
      label: "Phone",
      type: "phone",
      placeholder: "10 digit phone number...",
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      placeholder: "User email address...",
    },
  ];

  // Create new user and send ignitionSession link on form submit; return to ignitionSessions
  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      // Create user
      const user = await createUser(newUser);
      const userId = user.data.id;

      // Create ignition session
      const ignitionSession = await createIgnitionSession(userId);
      const ignitionToken = ignitionSession.ignitionToken;

      // Inform dashboard user of success and navigate to new created session
      toast.success("Ignition Session created.");
      navigate(`/ignition/${ignitionToken}`);
    } catch (error) {
      toast.error(error.message);
      setError(error.message);
      console.error(error);
    }
  };

  const onExistingSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      // Set user id
      if (!user) {
        alert("Please choose a user");
        return;
      }
      const userId = user.id;

      const ignitionSession = await createIgnitionSession(userId);

      // // Create Ignition Session based on type
      // if (!user) {
      //   alert("Please choose a ignitionSession type");
      //   return;
      // }

      // let ignitionSessionId = "";

      // // If type link, create a new ignition session
      // if (type === "link") {
      //   const ignitionSession = await createIgnitionSession(userId);
      //   ignitionSessionId = ignitionSession.id;
      // }

      // // If type link, create refresh ignition session for the specified policy
      // if (type === "refresh") {
      //   if (!policy) {
      //     alert("Please choose a policy");
      //     return;
      //   }
      //   const policyId = policy.id;

      //   // Create ignition session
      //   // Note this doesn't handle a "type" yet - to support refresh we need to add support for it?
      //   const ignitionSession = createIgnitionSession(userId);
      //   ignitionSessionId = ignitionSession.id;
      // }

      // Inform dashboard user of success and navigate to new created session
      toast.success("Ignition Session created.");
      navigate(`/ignition/${ignitionSession.ignitionToken}`);
    } catch (error) {
      toast.error(error.message);
      setError(error.message);
      console.error(error);
    }
  };

  return (
    <div className="w-full h-full bg-white z-10 flex flex-col gap-y-2 absolute">
      <div className="p-8 flex items-center justify-between border-b border-gray-200">
        <h1 className="text-2xl font-bold">New Ignition Session</h1>
        <div className="flex gap-x-4 items-center">
          {/* <ButtonSecondary text="Send and create another" /> */}

          {/* <button onClick={() => toast.error("link sent")}>Make me a toast</button> */}
          {/* <div className="h-8 w-px mx-2 bg-gray-300 mx-"></div> */}
          <Icon
            icon={Close}
            height="h-4"
            width="w-4"
            className="cursor-pointer transition hover:scale-110"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <div className="p-8 flex flex-col gap-y-8">
        <Tab.Group>
          <div className="text-sm font-medium">
            <Tab.List>
              <div className="flex flex-wrap">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={
                        selected
                          ? "text-left mr-2 inline-block p-4 border-b-2 border-gray-300 active hover:border-gray-300"
                          : "text-left mr-2 inline-block p-4 border-b-2 border-transparent hover:border-gray-300"
                      }>
                      New User
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={
                        selected
                          ? "text-left mr-2 inline-block p-4 border-b-2 border-gray-300 active hover:border-gray-300"
                          : "text-left mr-2 inline-block p-4 border-b-2 border-transparent hover:border-gray-300"
                      }>
                      Existing User
                    </button>
                  )}
                </Tab>
              </div>
            </Tab.List>
          </div>
          <Tab.Panels>
            <Tab.Panel>
              {promiseInProgress === true ? <p>Loading...</p> : null}
              <Form
                name="new-ignition-session"
                fields={newUserFields}
                formData={newUser}
                setFormData={setnewUser}
                onSubmit={onSubmit}
                error={error}
                button="Create Ignition Session"
              />
            </Tab.Panel>
            <Tab.Panel>
              {promiseInProgress === true ? <p>Loading...</p> : null}
              <form
                name="existing-user"
                className="flex flex-col gap-y-8 w-4/5 md:w-1/2"
                onSubmit={onExistingSubmit}>
                {/* User */}
                <Combobox value={user} onChange={setUser}>
                  <div className="flex flex-col gap-y-2 relative">
                    <label className="text-sm">Select User</label>
                    <div className="relative w-full cursor-default overflow-hidden">
                      <Combobox.Input
                        onChange={(event) => setQuery(event.target.value)}
                        placeholder={"Select a user..."}
                        displayValue={(user) =>
                          user && `${user.firstName} ${user.lastName}`
                        }
                        type="text"
                        className="w-full border border-solid border-gray-300 p-[0.625rem] text-sm rounded placeholder-gray-400"
                        required
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <Icon icon={Add} className="cursor-pointer" />
                      </Combobox.Button>
                    </div>

                    <Combobox.Options className="absolute mt-[4.625rem] z-10 max-h-60 w-full overflow-auto bg-white py-1 shadow-lg border border-solid border-gray-300 focus:outline-none text-sm rounded">
                      {filteredUsers.length === 0 && query !== "" ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          No users found.
                        </div>
                      ) : (
                        filteredUsers.map((user) => (
                          <Combobox.Option
                            key={user.id}
                            value={user}
                            className={({ active }) =>
                              `relative cursor-pointer select-none p-[0.625rem] flex flex-row gap-x-2 items-center ${
                                active
                                  ? "bg-gray-500 text-white fill-white"
                                  : "text-gray-900"
                              }`
                            }>
                            {({ selected, active }) => (
                              <>
                                {selected ? (
                                  <Checkmark className="h-3 w-3" />
                                ) : null}
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}>
                                  {user.firstName + " " + user.lastName}
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </div>
                </Combobox>

                {/* Session type */}
                {/* <RadioGroup
                  value={type}
                  onChange={setType}
                  className="flex flex-col gap-y-2 relative mt-1">
                  <RadioGroup.Label className="text-sm">
                    Ignition Session type
                  </RadioGroup.Label>
                  <div className="flex flex-col gap-y-2">
                    {ignitionSessionTypes.map((type) => (
                      <RadioGroup.Option
                        key={type}
                        value={type}
                        className={({ active, checked }) =>
                          `${
                            checked
                              ? " border-gray-400 text-white"
                              : "border-gray-300"
                          }
                          border relative flex cursor-pointer rounded p-[0.625rem] focus:outline-none flex-grow`
                        }>
                        {({ active, checked }) => (
                          <>
                            <div className="flex w-full items-center justify-between">
                              <div className="flex items-center">
                                <div className="text-sm">
                                  <RadioGroup.Label
                                    as="p"
                                    className={`text-black ${
                                      checked
                                        ? " text-black"
                                        : "font-normal text-gray-400"
                                    }`}>
                                    {type === "link"
                                      ? "Ignition Session link"
                                      : type === "refresh"
                                      ? "Refresh"
                                      : type}
                                  </RadioGroup.Label>
                                </div>
                              </div>
                              {checked && (
                                <div className="shrink-0 text-white">
                                  <Checkmark
                                    className={`h-3.5 w-3.5 fill-black`}
                                  />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup> */}

                {/* Policy */}
                {/* {type === "refresh" && (
                  <Listbox value={policy} onChange={setPolicy}>
                    <div className="flex flex-col gap-y-2 relative">
                      <label className="text-sm">Select policy</label>
                      <Listbox.Button className="border border-solid border-gray-300 p-[0.625rem] text-sm rounded placeholder-gray-400 text-left relative">
                        {policy ? (
                          policy.policyNumber
                        ) : (
                          <span className="text-gray-400">
                            Select a policy...
                          </span>
                        )}

                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <Add className="h-3 w-3" />
                        </span>
                      </Listbox.Button>
                      <Listbox.Options className="absolute mt-[4.625rem] z-10 max-h-60 w-full overflow-auto bg-white py-1 shadow-lg border border-solid border-gray-300 focus:outline-none text-sm rounded">
                        {policies.filter((policy) => policy.account).length ===
                        0 ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            No policies found.
                          </div>
                        ) : (
                          policies.map(
                            (policy) =>
                              policy.account && (
                                <Listbox.Option
                                  key={policy.id}
                                  value={policy}
                                  className={({ active }) =>
                                    `relative cursor-pointer select-none p-[0.625rem] flex flex-row gap-x-2 items-center ${
                                      active
                                        ? "bg-gray-500 text-white fill-white"
                                        : "text-gray-900"
                                    }`
                                  }>
                                  {({ selected }) => (
                                    <>
                                      {selected ? (
                                        <Checkmark className="h-3 w-3" />
                                      ) : null}
                                      <div className="flex flex-col gap-y-1">
                                        <span className="font-medium">
                                          {policy.type}
                                        </span>
                                        <span className="">{`Policy Number: ${policy.policyNumber}`}</span>
                                      </div>
                                    </>
                                  )}
                                </Listbox.Option>
                              )
                          )
                        )}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                )} */}

                <ButtonPrimary text="Create Ignition Session" type="submit" />
              </form>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default NewIgnitionSession;
