import _ from "lodash";

import { concatStrings } from "../../lib/utility";

export const Metadata = ({ value }) => {
  const metadata = JSON.parse(value);
  
  return (
    // Metadata container
    <div className="flex flex-col">
      {/* Map each metadata */}
      {_(metadata)
        .map((value, key) => {
          return (
            <div className="flex flex-row">
              {concatStrings([key, value], ": ")}
            </div>
          );
        })
        .value()}
    </div>
  );
};
