const Clock = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8ZM8 1.912A6.088 6.088 0 1 0 14.088 8 6.1 6.1 0 0 0 8 1.912Z" />
      <path d="M10.955 9.946h-4.9v-4.9h1.9v3h3v1.9Z" />
    </svg>
  );
};

export default Clock;
