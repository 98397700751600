const Shield = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path d="M7.97291 15.4522C3.25764 12.7471 1.17609 8.19704 1.54099 3.16174C1.55267 2.99669 1.67768 2.86669 1.83604 2.84343C3.96106 2.52173 6.96607 0.511722 8.14956 0.500037C9.4213 0.490056 12.3348 2.52014 14.4646 2.84343C14.633 2.86839 14.7547 3.01177 14.7597 3.17682C14.933 5.54188 14.5614 8.05538 13.3646 10.3404C12.3412 12.2971 10.7162 14.0807 8.32091 15.4539C8.20929 15.5189 8.07785 15.5122 7.97291 15.4522ZM3.54775 10.0203C4.48781 11.817 5.97114 13.462 8.1496 14.7505C12.413 12.2288 14.3397 8.09203 14.083 3.48021C12.1113 3.07013 8.86131 1.12014 8.19945 1.1934C8.1745 1.19669 8.14785 1.19669 8.12107 1.1951C7.49274 1.14337 4.19759 3.07012 2.21607 3.4802C2.09435 5.65355 2.46115 7.94183 3.54775 10.0203Z" />
      <path d="M7.72405 15.8859C2.82821 13.0772 0.664663 8.33596 1.04224 3.1256C1.07114 2.72295 1.37655 2.40617 1.762 2.34894C2.73899 2.20082 3.95218 1.65468 5.13419 1.10247C5.19685 1.0732 5.25942 1.04392 5.32181 1.01472C5.83283 0.775571 6.33218 0.541886 6.77474 0.3625C7.25395 0.168262 7.74344 0.0040206 8.14457 5.99898e-05L8.14559 5.09895e-05C8.57006 -0.0032804 9.07058 0.157069 9.55883 0.352234C10.0317 0.541264 10.5605 0.792011 11.0965 1.04617C11.1308 1.06241 11.1651 1.07867 11.1994 1.09493C12.3726 1.65092 13.5622 2.20073 14.5396 2.34909L14.4646 2.84343L14.5379 2.34884C14.9486 2.40971 15.2409 2.75455 15.2589 3.14871C15.4363 5.58695 15.0535 8.19338 13.8075 10.5724C12.739 12.615 11.0459 14.4679 8.56955 15.8877C8.29032 16.0488 7.96857 16.0258 7.72405 15.8859ZM8.24689 1.69125C8.18594 1.69786 8.13151 1.69659 8.09152 1.69423L8.08625 1.69392C8.08793 1.69407 8.08881 1.6942 8.08882 1.69438C8.0889 1.69572 8.04081 1.69996 7.91836 1.73852C7.78599 1.78021 7.61442 1.84597 7.40547 1.93368C7.06788 2.07539 6.66359 2.26114 6.21462 2.46742C6.10691 2.5169 5.99664 2.56757 5.88409 2.61909C4.8804 3.07859 3.69172 3.60952 2.69949 3.87794C2.64183 5.85614 3.01064 7.90281 3.97873 9.76553L3.99073 9.78847C4.85008 11.4309 6.18883 12.9486 8.14949 14.1658C11.9507 11.8038 13.7238 8.07426 13.5994 3.87717C12.612 3.60704 11.4341 3.07047 10.4396 2.60913C10.3575 2.57104 10.2766 2.53345 10.197 2.49646C9.7173 2.27348 9.2857 2.07286 8.92803 1.92354C8.71979 1.83661 8.54895 1.77234 8.41687 1.73247C8.29454 1.69554 8.24684 1.69252 8.24689 1.69125C8.24659 1.69132 8.24689 1.69118 8.24689 1.69125Z" />
    </svg>
  );
};

export default Shield;
