import { Link } from "react-router-dom";
import React, { useMemo, useState } from "react";

import {
  useTable,
  useFlexLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import ChevronLeft from "../../icons/ChevronLeft";
import ChevronRight from "../../icons/ChevronRight";
import ButtonSecondary from "../../components/ButtonSecondary";
import { GlobalFilter, DefaultFilterForColumn, QuickFilter } from "./Filter";
import usePaginationRange, { DOTS } from "../TablePagination";
import _ from "lodash";
import { CSVLink } from "react-csv";
import Download from "../../icons/Download";
import DateRangePicker from "../DateRangePicker";

/**
 * It renders a table with pagination, filtering, and sorting
 * @returns A table component that is being rendered in the view.
 */
const Table = ({
  entity,
  data,
  columns,
  rowBehavior,
  globalFilterPlaceholder,
  searchItems,
  quickFilters,
  csvFields,
  dateFilter,
}) => {
  const [activePaginationButton, setActivePaginationButton] = useState(1);

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 100, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  // Create a state
  const [filterInput, setFilterInput] = useState("");
  const [CSVData, setCSVData] = useState([]);

  // Update the state when input changes
  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("type", value); // Update the show.name filter. Now our table will filter and show only the rows which have a matching value
    setFilterInput(value);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state,
    rows,
    prepareRow,
    setGlobalFilter,
    setFilter,
    preGlobalFilteredRows,
    flatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn: { Filter: DefaultFilterForColumn },
      useFlexLayout,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const siblingCount = 1;
  const totalPageCount = pageCount * pageSize;

  const paginationRange = usePaginationRange(
    totalPageCount,
    pageSize,
    siblingCount,
    pageIndex
  );

  const getCSVData = (fields) => {
    // From https://konklone.io/json/ but updated to use ES6 and lodash
    const parse_object = (obj, path) => {
      if (path == undefined) path = "";

      const scalar =
        _.isNumber(obj) || _.isString(obj) || _.isBoolean(obj) || _.isNull(obj);

      if (_.isObject(obj)) {
        const d = {};
        for (const i in obj) {
          const newD = parse_object(obj[i], path + i + "/");
          _.extend(d, newD);
        }

        return d;
      } else if (scalar) {
        const d = {};
        const endPath = path.substr(0, path.length - 1);
        d[endPath] = obj;
        return d;
      }

      // ?
      else return {};
    };

    // Extract and parse data from from the filtered data
    const extractedCSVData = flatRows.map((row) => {
      // Extract desired fields
      let extractedData = _(row).chain().get("values").pick(fields).value();

      // Loop thru fields to parse objects
      fields.forEach((field) => {
        if (
          _(allColumns)
            .chain()
            .find((col) => col.id === field)
            .get("isObject")
            .eq(true)
            .value() &&
          extractedData[field]
        ) {
          const parsedField = JSON.parse(extractedData[field]);
          extractedData[field] = parsedField;
        }
      });

      // Recursively flatten object
      const flattenedField = parse_object(extractedData);
      return flattenedField;
    });

    // Update state
    setCSVData(extractedCSVData);
  };

  return (
    <div className="flex flex-col gap-y-8">
      {/* Quick filter */}

      {quickFilters && (
        <QuickFilter
          setFilter={setFilter}
          filters={quickFilters}
          preGlobalFilteredRows={preGlobalFilteredRows}
        />
      )}

      <div className="flex flex-row items-center gap-x-4">
        {/* Global filter */}
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          globalFilterPlaceholder={globalFilterPlaceholder}
        />
        {dateFilter && <DateRangePicker dateFilter={dateFilter} />}
        {csvFields && (
          <CSVLink
            filename={`${entity}-${new Date().toISOString()}.csv`}
            data={CSVData}
            onClick={() => {
              getCSVData(csvFields);
            }}>
            <ButtonSecondary icon={Download} text={"Export"} />
          </CSVLink>
        )}
      </div>
      {/* Table */}
      <div {...getTableProps()} className="flex flex-col">
        {/* Table header */}
        <TableHeader headerGroups={headerGroups} />

        {/* Table body */}
        <TableBody getTableBodyProps={getTableBodyProps}>
          {page.map((row) => {
            // Prepare rows
            prepareRow(row);

            // Render rows
            switch (_(rowBehavior).get("type")) {
              case "link":
                const resourceId = row.original[rowBehavior.config.resourceKey];
                return (
                  <Link to={rowBehavior.config.path + "/" + resourceId}>
                    <TableRow key={row.index} row={row} />
                  </Link>
                );
              default:
                return <TableRow key={row.index} row={row} />;
            }
          })}
        </TableBody>

        {/* Pagination */}
        <Pagination
          rows={rows}
          pageIndex={pageIndex}
          pageSize={pageSize}
          paginationRange={paginationRange}
          previousPage={previousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </div>
    </div>
  );
};

const TableHeader = ({ headerGroups }) => {
  return (
    <div>
      {/* Render header group */}
      {headerGroups.map((headerGroup) => (
        <div
          {...headerGroup.getHeaderGroupProps({
            // style: { paddingRight: '15px' },
          })}
          className="flex flex-row mb-3">
          {/* Render each column */}
          {headerGroup.headers.map((column) => (
            <div
              // Column styling
              {...column.getHeaderProps([
                {
                  className:
                    "flex-auto uppercase text-[0.625rem] font-medium text-ellipsis overflow-hidden whitespace-nowrap px-2",
                },
                { className: column.className },
                {
                  style: { width: column.width },
                },
              ])}>
              {/* Render header */}
              {column.render("Header")}

              {/* Render default column filter */}
              <div>{column.canFilter ? column.render("Filter") : null}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const TableBody = ({ getTableBodyProps, children }) => {
  return (
    <div
      {...getTableBodyProps()}
      //  Render table body
      className="overflow-y-scroll overflow-x-hidden divide-y">
      {children}
    </div>
  );
};

const TableRow = ({ row }) => {
  return (
    <div
      {...row.getRowProps()}
      // Row styling
      className="flex flex-col md:flex-row items-start transition hover:bg-gray-50">
      {/* Map and render cells */}
      {row.cells.map((cell) => {
        return (
          <div
            // Cell styling
            {...cell.getCellProps([
              { className: cell.column.className },
              {
                className: `flex-auto py-4 text-sm text-ellipsis overflow-hidden whitespace-nowrap border-gray-200 px-2 ${
                  !cell.column.isVisible && "hidden"
                }`,
              },
              {
                style: { width: cell.column.width },
              },
            ])}>
            {/* Render each cell */}
            {cell.render("Cell")}
          </div>
        );
      })}
    </div>
  );
};

const Pagination = ({
  rows,
  pageIndex,
  pageSize,
  paginationRange,
  previousPage,
  gotoPage,
  nextPage,
  canPreviousPage,
  canNextPage,
}) => {
  return (
    // For later - can we break this up?
    <div className="flex items-center justify-between py-3">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        {/* Pagination info */}
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            {rows.length ? (
              <>
                <span key="range-of-records" className="font-medium">
                  {pageIndex * pageSize + 1}-
                  {(pageIndex + 1) * pageSize < rows.length
                    ? (pageIndex + 1) * pageSize
                    : rows.length}
                </span>{" "}
                of{" "}
              </>
            ) : (
              <></>
            )}
            <span key="total-records" className="font-medium">
              {rows.length}
            </span>{" "}
            records
          </p>
        </div>

        {/* Pagination nav */}
        <nav
          className="isolate inline-flex -space-x-px"
          aria-label="Pagination">
          {/* Arrow left */}
          <a
            key="left-arrow"
            href="#"
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}>
            <span key="left-arrow-text" className="sr-only">
              Previous
            </span>
            <ChevronLeft className="w-2.5 h-2.5 fill-gray-500" />
          </a>

          {/* Page numbers */}
          {paginationRange.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return (
                <span
                  key={"dots"}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700">
                  {DOTS}
                </span>
              );
            }

            return (
              <a
                key={pageNumber}
                className={
                  pageIndex == pageNumber - 1
                    ? "relative z-10 inline-flex items-center border border-gray-200 px-4 py-2 text-sm font-medium text-gray-600 focus:z-20"
                    : "relative hidden items-center px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                }
                onClick={() => {
                  gotoPage(pageNumber - 1);
                }}>
                {pageNumber}
              </a>
            );
          })}

          {/* Arrow right */}
          <a
            key="right-arrow"
            href="#"
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            onClick={() => {
              nextPage();
              //setActivePaginationButton(activePaginationButton + 1);
            }}
            disabled={!canNextPage}>
            <span key="right-arrow-text" className="sr-only">
              Next
            </span>
            <ChevronRight className="w-2.5 h-2.5 fill-gray-500" />
          </a>
        </nav>
      </div>
    </div>
  );
};

export default Table;
