import { isEmpty, get, startCase } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Callout from "../components/Callout";
import {
  getPolicy,
  getPolicyForms,
  getUser,
  validatePolicy,
} from "../lib/automatic";
import LoadingSpinner from "../components/LoadingSpinner";
import Policy from "../components/Policy";

import {
  DetailsPage,
  DetailsPageCard,
  DetailsPageColumn,
} from "../components/DetailsPage";
import { ClientContext } from "../App";
import { trackPromise } from "react-promise-tracker";
import PolicyValidation from "../components/PolicyValidation";

const PolicyDetails = () => {
  let params = useParams();
  const [policy, setPolicy] = useState();
  const [user, setUser] = useState();
  const [validation, setValidation] = useState();
  const [policyForms, setPolicyForms] = useState([]);
  const [callout, setCallout] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const client = useContext(ClientContext);

  const fetchData = async (policyId) => {
    // Fetch the policy from the server and set it to state
    let fetchedPolicy;
    try {
      fetchedPolicy = await getPolicy(policyId);
      setPolicy(fetchedPolicy);
    } catch (e) {
      console.log(e);
    }

    // Get policy forms for this policy if client config has forms = true and the policy has a state
    try {
      if (
        get(client, "config.validationConfig.forms") &&
        get(fetchedPolicy, "address.state")
      ) {
        const state = get(fetchedPolicy, "address.state");
        let carrier = get(fetchedPolicy, "carrier");
        // Get forms for this carrier and state
        const forms = await getPolicyForms(carrier, state);
        setPolicyForms(forms);
      }
    } catch (e) {
      console.error("Error getting policy forms: ");
      console.error(e);
    }

    // If policy has a user, fetch the user from the server and set to state
    if (fetchedPolicy.user) {
      const fetchedUser = await getUser(user).catch((e) => console.log(e));
      setUser(fetchedUser);
    }

    // If policy is missing status, set processing callout
    if (
      fetchedPolicy.isActive === undefined ||
      fetchedPolicy.isActive === null
    ) {
      if (
        !isEmpty(fetchedPolicy.insureds) ||
        !isEmpty(fetchedPolicy.expirationDate) ||
        !isEmpty(fetchedPolicy.policyNumber)
      ) {
        // Assume manual callout if the policy has insureds
        setCallout({
          type: "warning",
          message:
            "Please note that this policy information was manually uploaded by the user and has not been verified through Axle.",
        });
      } else {
        // Else set basic callout
        setCallout({
          type: "warning",
          message:
            "Connection to the policy is still being established. Limited details available, please check back later for updates.",
        });
      }
    }

    return;
  };

  useEffect(() => {
    if (!client) {
      return;
    }
    trackPromise(fetchData(params.policyId).then(() => setIsLoading(false)));
  }, [client]);

  const renderDetails = policy && (
    <DetailsPage
      title={policy.id}
      badge={
        policy.isActive === true
          ? "active"
          : policy.isActive === false
          ? "inactive"
          : undefined
      }
      actions={[
        {
          type: "copy",
          label: "Copy ID",
          value: policy.id,
        },
      ]}
    >
      {/* Primary column */}
      <DetailsPageColumn primary>
        {callout && <Callout type={callout.type} message={callout.message} />}
        {/* Policy details */}
        <Policy policy={policy} policyForms={policyForms} />
      </DetailsPageColumn>

      {/* Secondary column */}
      <DetailsPageColumn>
        {/* User */}
        {user && (
          <DetailsPageCard title="User">
            <div className="flex flex-col divide-y gap-y-2">
              <div className="flex flex-col">
                <h3 className="uppercase text-[0.625rem] font-medium">Name</h3>
                <p className="text-sm">
                  {/* This should be replaced with ignitionSession info*/}
                  {startCase(user.firstName)} {startCase(user.lastName)}
                </p>
              </div>
              <div className="flex flex-col pt-2">
                <h3 className="uppercase text-[0.625rem] font-medium">Phone</h3>
                <p className="text-sm">{user.phone}</p>
              </div>
              <div className="flex flex-col pt-2">
                <h3 className="uppercase text-[0.625rem] font-medium">Email</h3>
                <p className="text-sm">{user.email ?? "—"}</p>
              </div>
            </div>
            {/* This should be filled in with information from the user object */}
          </DetailsPageCard>
        )}
        {get(client, "config.validationConfig.enabled") && (
          <PolicyValidation
            title="Policy Validation"
            iconStatus="shimmer"
            client={client}
            policyId={policy.id}
          />
        )}
      </DetailsPageColumn>
    </DetailsPage>
  );

  return <>{isLoading ? <LoadingSpinner item="details" /> : renderDetails}</>;
};

export default PolicyDetails;
