import React, { useContext } from "react";

import Info from "../icons/Info";
import Link from "../icons/Link";
import NavItem from "./NavItem";
import Shield from "../icons/Shield";
import SvgLogo from "../icons/Logo";
import { genericClientLogoUrl } from "../lib/constants";
import { ClientContext } from "../App";

const Sidebar = () => {
  const client = useContext(ClientContext);

  return (
    <div className="flex flex-col h-full w-52 bg-gray-50 px-4 py-6 justify-between fixed">
      <div className="flex flex-col gap-y-8">
        <div className="flex gap-x-2 items-center mx-1">
          <div
            className="inline-block rounded-full h-7 w-7 bg-white bg-5/8 bg-no-repeat bg-cover bg-center"
            style={{
              backgroundImage: `url('${
                client.logoUrl ?? genericClientLogoUrl
              }')`,
            }}
          ></div>
          <h2 className="inline-block text-sm text-black font-medium text-ellipsis overflow-hidden whitespace-nowrap">
            {client.displayName}
          </h2>
        </div>
        <div className="text-black text-sm flex flex-col gap-y-1">
          <NavItem to={"/ignition"} icon={Link} label="Ignition Sessions" />
          <NavItem to={"/policies"} icon={Shield} label="Policies" />
          <NavItem to={"/carriers"} icon={Info} label="Carriers" />
        </div>
      </div>{" "}
      <SvgLogo className="h-4 margin-auto" />
    </div>
  );
};

export default Sidebar;
