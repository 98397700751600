import { React } from "react";
import ButtonPrimary from "./ButtonPrimary";
import Phone, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input/input";

const Form = ({
  name,
  fields,
  formData,
  setFormData,
  onSubmit,
  error,
  button,
}) => {
  return (
    <form
      id={name}
      className="w-4/5 md:w-1/2 flex flex-col gap-y-8"
      onSubmit={onSubmit}
    >
      <div className="flex flex-col gap-y-6">
        {error && (
          <div className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1">
            {" "}
            {error}{" "}
          </div>
        )}
        {fields.map((field) => (
          <div className="flex flex-col gap-y-2" key={field.id}>
            <label className="text-sm">{field.label}</label>
            {field.type !== "phone" ? (
              <input
                placeholder={field.placeholder}
                value={formData[field.id]}
                className="border border-solid border-gray-300 p-[0.625rem] text-sm rounded placeholder-gray-400"
                type={field.type}
                onChange={(e) =>
                  setFormData({ ...formData, [field.id]: e.target.value })
                }
                required
              />
            ) : (
              <Phone
                placeholder={field.placeholder}
                defaultCountry="US"
                value={formData[field.id]}
                className="border border-solid border-gray-300 p-[0.625rem] text-sm rounded placeholder-gray-400"
                onChange={(e) => setFormData({ ...formData, [field.id]: e })}
                required
              />
            )}

            {field.type === "phone" && formData[field.id] ? (
              isPossiblePhoneNumber(formData[field.id], "US") ? (
                isValidPhoneNumber(formData[field.id], "US") ? undefined : (
                  <span className="text-red-700 text-sm -mt-1">
                    Invalid phone number
                  </span>
                )
              ) : (
                <span className="text-red-700 text-sm -mt-1">
                  Invalid phone number
                </span>
              )
            ) : undefined}
          </div>
        ))}
        <ButtonPrimary text={button} type="submit" />
      </div>

      {/* <ButtonPrimary
        text={"Submit"}
        //onClick={() => nextStep(step)}
        width={"w-full"}
        type={"submit"}
      /> */}
      {/* <input type="submit" id={name} class="hidden" /> */}
    </form>
  );
};

export default Form;
