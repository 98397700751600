const CarrierLogo = ({ carrier }) => {
  const logoBucket = "https://axle-labs-assets.s3.amazonaws.com/carrierLogos/";
  carrier.image = carrier.hasImage
    ? logoBucket + carrier.id + ".svg"
    : logoBucket + "generic.svg";
  return (
    <div
      className="inline-block rounded-full h-10 w-10 bg-5/8 bg-no-repeat bg-cover bg-center"
      style={{ "backgroundImage": `url('${carrier.image}')` }}></div>
  );
};

export default CarrierLogo;
